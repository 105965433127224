<template>
    <Txt v-bind="$attrs">
        <span class="gradient-text" :class="variant">
            <slot />
        </span>
    </Txt>
</template>

<script>
import Txt from '@/components/form/txt';

export default {
    name: 'GradientText',
    components: { Txt },
    props: {
        variant: { type: String, default: 'blue' }
    }
};
</script>

<style lang="less">
@keyframes wipe {
    to { background-position: 300% center; }
}

.gradient-text {
    background-size: 300% auto;
    color: transparent;
    background-clip: text;
    animation-duration: 15s;
    animation-name: wipe;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    &.blue { background-image: radial-gradient(circle at center, #111852 0%, #446CFC 100%); }
    &.lightblue { background-image: radial-gradient(circle at center, #FFFFFF 0%, #98D6E4 100%); }
}
</style>
